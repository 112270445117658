import { api } from 'api'
import { compose } from 'recompose'
import { formatYearMonthDay, getOr, reformatDatesForApi, set, unset, setIf } from 'utils'
import { SubmissionError } from 'redux-form'
import * as LS from 'local-storage'
import { NEW_MEMBERSHIP_ID } from 'config'
import { isEmpty } from 'lodash'

export function checkCapacity (activityTimeId) {
  const url = `/ticketing/capacities/${ activityTimeId }`
  return api.get(url)
}

export function holdTickets (lineItem) {
  const url = `/ticketing/event_holds/${ lineItem.id }?cart_id=${lineItem.cartId}`
  return api.post(url)
}

export function updateCheckout (newOrderInfo = {}, coupon = null, activity = {}) {
  const udfOrderInfo = compose(
    setIf(
      !!(activity && activity.bookingUdfs),
      'bookingUdfs',
      mapUdfValues(activity && activity.bookingUdfs, newOrderInfo.bookingUdfForms)
    ),
    setIf(
      newOrderInfo.attendees && newOrderInfo.attendees.length && activity && activity.attendeeUdfs,
      'attendeeDetails',
      getOr([], 'attendees', newOrderInfo).map(attendee => {
        return set('attendeeUdfs', mapUdfValues(activity && activity.attendeeUdfs, attendee), attendee)
      })
    )
  )({})

  const orderInfoParams = {
    orderInfo: {
      bookingTypeId: newOrderInfo.bookingTypeId,
      activityId: newOrderInfo.activityId,
      bookingTimeId: newOrderInfo.activityTimeId,
      startDate: formatYearMonthDay(newOrderInfo.startDate),
      endDate: formatYearMonthDay(newOrderInfo.startDate),
      coupon: coupon,
      tickets: mapTickets(newOrderInfo.orderTickets, newOrderInfo),
      addOns: mapAddOns(newOrderInfo.orderAddOns, newOrderInfo),
      ...udfOrderInfo,
    }
  }
  const updatedOrderInfo = {
    ...newOrderInfo,
    ...orderInfoParams.orderInfo,
  }

  return api.post('/ticketing/checkouts', orderInfoParams)
    .then((success) => updateOrderInfoTotals({ success }, updatedOrderInfo))
}

function mapAddOns (orderAddOns, newOrderInfo = {}) {
  if (newOrderInfo.attendees && newOrderInfo.attendees.length > 0){
    orderAddOns = {}
    newOrderInfo.attendees.forEach((attendee) => {
      if (attendee.addOns){
        attendee.addOnIds = []
        Object.keys(attendee.addOns).map(key => {
          const formattedKey = parseAddOnId(key)
          const count = attendee.addOns[key] ? 1 : 0
          if (count) attendee.addOnIds.push(formattedKey)
          orderAddOns[formattedKey] = (orderAddOns[formattedKey] || 0) + count
        })
      }
    })
  }
  return Object.keys(orderAddOns).map(key => {
    const value = orderAddOns[key]
    const addOnHash = { centamanId: parseInt(key, 10), quantity: value, activityTimeId: newOrderInfo.activityTimeId }
    return addOnHash
  }).filter((addOn) => addOn.quantity > 0)
}

function mapTickets (orderTickets, newOrderInfo = {}) {
  return Object.keys(orderTickets).map(key => {
    const value = orderTickets[key]
    const ticketHash = { centamanId: parseInt(key, 10), quantity: value, activityTimeId: newOrderInfo.activityTimeId }
    return ticketHash
  }).filter((ticket) => ticket.quantity > 0)
}

function updateOrderInfoTotals ({ success: { totals, tickets } }, newOrderInfo) {
  if (newOrderInfo.attendeeDetails){
    newOrderInfo.attendeeDetails.forEach((attendee) => {
      if (attendee.dateOfBirth){
        attendee.dateOfBirth = formatYearMonthDay(attendee.dateOfBirth)
      }
    })
  }
  const updatedOrderInfo = {
    ...newOrderInfo,
    totalTaxes: totals.totalTaxes,
    orderTotal: totals.total,
    orderSubtotal: totals.subtotal,
    displayTickets: totals.displayTickets,
  }
  return { orderInfo: updatedOrderInfo, couponDescription: totals.couponDescription, tickets }
}

export function createTicketingOrder (cart, params, skipPaymentDetails, editMode) {
  if(skipPaymentDetails) return submitTicketingOrder(null, cart, params, editMode)
  return submitPaymentMethodToSpreedly(params)
    .then((response) => {
      if (response.transaction) return submitTicketingOrder(response.transaction, cart, params, editMode)
    })
}

export function submitTicketingOrder(spreedlyTransaction, cart, customerInfo, editMode) {
  let orderEndpoint = `/ticketing/orders?cart_token=${cart.token}`
  if (editMode) orderEndpoint += `&edit_mode=true`
  const { firstName, lastName, email, phone, bookingId, orderToken } = customerInfo
  const orderBody = {
    order: {
      bookingId,
      orderToken,
      customerInfo: {
        firstName,
        lastName,
        email,
        phone,
        cardType: spreedlyTransaction ? spreedlyTransaction.paymentMethod.cardType : null,
        cardToken: spreedlyTransaction ? spreedlyTransaction.paymentMethod.token : null,
        lastFourDigits: spreedlyTransaction ? spreedlyTransaction.paymentMethod.lastFourDigits : null
      }
    }
  }
  if (isEmpty(cart.appliedCoupons)) return api.post(orderEndpoint, orderBody)
  return submitCouponHolds(cart)
    .then(() => {
      return api.post(orderEndpoint, orderBody)
    })
}

export function submitCouponHolds(cart) {
  return api.post(`/ticketing/coupons/hold?cart_token=${cart.token}`, {})
}

export function createMembershipOrder ({ params, skipPaymentDetails }) {
  const checkout = LS.getCheckout()

  // Combine secondary and add on members into one array and remove ids from newly added members
  const combinedSecondaryMembers = [
    ...getOr([], 'secondaryMembers', checkout),
    ...getOr([], 'addOnMembers', checkout)
  ].map(member => {
    if (member.id === NEW_MEMBERSHIP_ID) return unset('id', member)
    return member
  })

  const mappedOrderInfo = set('secondaryMembers', combinedSecondaryMembers, checkout)

  if(skipPaymentDetails) return submitOrder(params, { orderInfo: mappedOrderInfo, urlPrefix: 'memberships', skipPaymentDetails })
  return submitPaymentMethodToSpreedly(params)
    .then((response) => {
      if (response.transaction) return submitOrder(response, { orderInfo: mappedOrderInfo, urlPrefix: 'memberships' })
    })
}

function mappedCustomerInfo (params = {}) {
  return {
    firstName: params.firstName,
    lastName: params.lastName,
    email: params.email,
    phone: params.phone,
  }
}

function submitOrder (response, { orderInfo, bookingData, coupon, urlPrefix = 'ticketing', skipPaymentDetails }) {
  const token = skipPaymentDetails ? 'no token' : response.transaction.paymentMethod.token
  const customerInfo = skipPaymentDetails ? mappedCustomerInfo(response) : customerInfoData(response)
  const orderParams = {
    order: {
      orderInfo: urlPrefix == 'memberships' ? reformatDatesForApi(orderInfo) : orderInfoData({ orderInfo, bookingData, coupon }),
      customerInfo: customerInfo,
      cardToken: token
    }
  }
  const urlPath = [urlPrefix, 'orders.json'].join('/')
  return api.post(urlPath, orderParams).then((response) => {
    return response
  }).catch((...error) => {
    const errors = error[0].response
    throw new SubmissionError(errors)
  })
}

export function submitPaymentMethodToSpreedly (params) {
  const environmentKey = process.env.SPREEDLY_ENVIRONMENT_KEY
  const url = `https://core.spreedly.com/v1/payment_methods.json?environment_key=${environmentKey}`
  const paymentParams = {
    paymentMethod: {
      creditCard: {
        kind: 'credit_card',
        fullName: `${params.firstName} ${params.lastName}`,
        number: params.creditCard,
        verificationValue: params.verificationCode,
        month: params.expMonth,
        year: params.expYear,
        retained: true
      },
      email: params.email,
      phoneNumber: params.phone,
      retained: true
    }
  }

  return api.post(url, paymentParams, { root: '' }).then((response) => {
    return response
  }).catch((...error) => {
    const errors = {
      spreedly: true,
      title: 'Payment Method Error',
      message: 'The following fields have errors',
      errors: formatErrors(error[0].response.errors)
    }
    throw new SubmissionError(errors)
  })
}

function orderInfoData ({ orderInfo, bookingData, coupon }) {
  const bookingInfo = bookingData || {}
  return {
    ...orderInfo,
    bookingTypeId: orderInfo.bookingTypeId,
    activityId: orderInfo.activityId,
    bookingTimeId: orderInfo.activityTimeId,
    coupon: coupon || null,
    productArea: orderInfo.productArea || null,
    extras: [],
    tickets: mapTickets(orderInfo.orderTickets, orderInfo),
    addOns: mapAddOns(orderInfo.orderAddOns, orderInfo),
    startDate: formatYearMonthDay(orderInfo.startDate),
    endDate: formatYearMonthDay(orderInfo.startDate),
    bookingName: bookingInfo.bookingName,
    bookingStartDate: bookingInfo.bookingStartDate,
    bookingStartTime: bookingInfo.bookingStartTime,
  }
}

function customerInfoData (response) {
  return {
    firstName: response.transaction.paymentMethod.firstName,
    lastName: response.transaction.paymentMethod.lastName,
    email: response.transaction.paymentMethod.email,
    phone: response.transaction.paymentMethod.phoneNumber,
    cardType: response.transaction.paymentMethod.cardType,
    lastFourDigits: response.transaction.paymentMethod.lastFourDigits,
  }
}

function formatErrors (errors) {
  const errorsObject = {}
  errors.forEach((e) => {
    errorsObject[e.attribute] = e.message
  })
  return errorsObject
}

const parseAddOnId = (key = '') => key.replace(/_/g,'')

export function checkTicketCoupon ({ params: { coupon }, activityId, activityTimeId }) {
  const params = `?activity_id=${ activityId }&activity_time_id=${ activityTimeId}`
  const url = `/ticketing/coupons/${ coupon }${params}`
  return api.get(url)
}

export function checkCoupon ({ params: { coupon }, orderInfo: { productArea }, activityId, activityTimeId }) {
  const params = `?product_area=${ productArea }&activity_id=${ activityId }&activity_time_id=${ activityTimeId}`
  const url = `/coupons/${ coupon }.json${params}`
  return api.get(url)
}

// ----- PRIVATE -----
function mapUdfValues (udfs, formValues) {
  if (!udfs || !udfs.length) return []

  return udfs.map(udf => {
    return set('value', formValues[udf.fieldName], udf)
  })
}
